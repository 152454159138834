@font-face {
  font-family: "HeeboRegular";
  src: url("/fonts/Heebo-Regular.ttf") format("truetype"),
    url("/fonts/Heebo-Regular.woff2") format("woff2"),
    url("/fonts/Heebo-Regular.woff") format("woff");
}

/* Heebo-Medium - font-weight is 500 */
@font-face {
  font-family: "HeeboMedium";
  src: url("/fonts/Heebo-Medium.ttf") format("truetype"),
    url("/fonts/Heebo-Medium.woff2") format("woff2"),
    url("/fonts/Heebo-Medium.woff") format("woff");
}

@font-face {
  font-family: "HeeboBold";
  src: url("/fonts/Heebo-Bold.ttf") format("truetype"),
    url("/fonts/Heebo-Bold.woff2") format("woff2"),
    url("/fonts/Heebo-Bold.woff") format("woff");
}

@font-face {
  font-family: "HeeboLight";
  src: url("/fonts/Heebo-Light.ttf") format("truetype"),
    url("/fonts/Heebo-Light.woff2") format("woff2"),
    url("/fonts/Heebo-Light.woff") format("woff");
}

@font-face {
  font-family: "NextExitFOTLight";
  src: url("/fonts/NextExitFOT-Light.ttf") format("truetype"),
    url("/fonts/NextExitFOT-Light.woff2") format("woff2"),
    url("/fonts/NextExitFOT-Light.woff") format("woff");
}

@font-face {
	font-family: 'RAGSans1.3Regular';
	src: url('/fonts/RAG-Sans-1.3-Regular.woff2') format('woff2'),
		url('/fonts/RAG-Sans-1.3-Regular.woff') format('woff');
}

@font-face {
	font-family: 'RAGSans1.3Light';
	src: url('/fonts/RAG-Sans-1.3-Light.woff2') format('woff2'),
		url('/fonts/RAG-Sans-1.3-Light.woff') format('woff');
}

@font-face {
	font-family: 'RAGSans1.3Bold';
	src: url('/fonts/RAG-Sans-1.3-Bold.woff2') format('woff2'),
		url('/fonts/RAG-Sans-1.3-Bold.woff') format('woff');
}
