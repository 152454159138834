.drawer-modal-container {
  width: 640px;
  padding: 75px 100px;
  position: absolute;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 100 !important;
  font-family: "RAG-Sans-65";
}

.salon-registration-container {
  display: flex;
  align-items: center;
}

.label-name {
  color: darkgrey;
  font-family: "RAGSans1.3Light";
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 10px;
}

.drawer-hr {
  width: 100%;
}

.salon-details-header {
  font-family: "RAGSans1.3Bold";
  font-size: 22px;
  line-height: 20px;
}

.details-content {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  width: 100%;
}

.success-header {
  font-size: 26px;
  line-height: 30px;
  font-family: "RAGSans1.3Regular";
}

.details-header {
  font-family: "RAGSans1.3Regular";
}

.popup-success-subtitle {
  font-family: "RAGSans1.3Light";
  margin-bottom: 1rem;
  line-height: 30px;
  font-size: 20px;
  color: #222020;
  font-weight: 300;
  margin-top: 3px;
}

.success-icon {
  width: 11rem;
  height: 10rem;
  margin-bottom: 25px;
}

@media only screen and (max-width: 760px) {
  .success-icon {
    width: 8rem;
    height: 8rem;
    margin-bottom: 18px;
  }
}

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 50px 105px 0 105px;
  height: 477px;
  top: 0;
  color: black;
  font-style: normal;
  font-family: "RAGSans1.3Light";
  font-weight: 300;
}
@media only screen and (max-width: 760px) {
  .success-container {
    padding: 20px 20px 0 20px;
  }
}

a {
  color: black;
  font-size: 18px;
  text-decoration: underline;
  font-family: "RAGSans1.3Light";
}

.host-details-popup {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.host-salon-details-popup {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 30px;
  font-size: 20px;
}

@media only screen and (max-width: 760px) {
  .host-salon-details-popup {
    flex-direction: column;
  }
}

.host-salon-details-popup div {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: black;
  margin: 0 10px;
}

.host-salon-details div {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: black;
  margin: 0 10px;
}

.more-details-content {
  font-family: "RAGSans1.3Light";
  margin-top: 18px;
  line-height: 25px;
  margin-bottom: 20px;
}

.no-salon-icon {
  width: 160px;
  height: 160px;
}

.delete-salon-icon {
  width: 110px;
  height: 110px;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background-color: rgba(217, 217, 217, 0.35);
  border: none;
  font-size: 16px;
  width: 164px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.max-guests {
  color: #727272;
  font-size: 14px;
  min-height: 19px;
}

.number-input {
  border: 2px solid #ddd;
  display: inline-flex;
  border: none;
  margin-inline-end: 30px;
  max-width: 170px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 760px) {
  .number-input {
    max-width: 150px;
  }
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: rgba(217, 217, 217, 0.35);
  border: none;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 56px;
  cursor: pointer;
  margin: 0;
  padding-left: 20px;
  position: relative;
}

.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: "";
  width: 1rem;
  height: 2px;
  background-color: #212121;
  transform: translate(-50%, -50%);
}
.number-input button.disabled:before,
.number-input button.disabled:after {
  background-color: #ccc;
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type="number"] {
  font-family: sans-serif;
  max-width: 5rem;
  padding: 0.5rem;
  border: none;
  border-width: 0 2px;
  font-size: 2rem;
  height: 56px;
  font-weight: bold;
  text-align: center;
}

.salon-name-header {
  padding-top: 35px;
  padding-inline-start: 16%;
  margin-bottom: 20px;
}
@media only screen and (max-width: 760px) {
  .salon-name-header {
    padding-inline-start: 0px;
  }
}
.x-icon {
  height: 100%;
  width: 100%;
}
.inputs-container {
  gap: 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 35px;
  padding-inline-start: 16%;
}
@media only screen and (max-width: 760px) {
  .inputs-container {
    padding-inline-start: 0%;
  }
}

.submit-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0 auto;
  flex: 1;
  margin-bottom: 20px;
}

.close-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.disabled-plus,
.disabled-minus {
  pointer-events: none;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.527) !important;
}

.register-popup-container {
  background-color: red;
  width: 200px;
  position: absolute;
  top: 20px;
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

.my-salon-comment {
  margin: 15px 0;
}

.my-salon-hr {
  right: 0;
  background-color: #e6e6e6;
  height: 0.5px;
  width: 640px;
  border: none;
  position: absolute;
}

.edit-div-guest {
  position: relative;
}

.edit-button-guest {
  position: absolute;
  top: 135px;
}

.mail-input {
  margin-top: 30px;
}

h3 {
  font-size: 22px;
}

.new-salon-host-header {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
}

.new-salon-header {
  font-family: "RAGSans1.3Regular";
  font-size: 24px;
  margin-bottom: 27px;
}

.refresh-page-comment {
  margin-bottom: 20px;
  font-size: 14px;
}

.new-salon-header-my-salon {
  text-align: start;
  font-family: "RAGSans1.3Regular";
  font-size: 24px;
}

@media only screen and (max-width: 760px) {
  .new-salon-header-my-salon {
    font-size: 20px;
  }
}

.action-btns-popup {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
@media only screen and (max-width: 760px) {
  .action-btns-popup {
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
  }
}

.action-btns-popup .bzGuXK {
  height: 39px;
  width: 154px;
}

.container-styles {
  height: 3px;
  background-color: #e0e0de;
  border-radius: 50;
  margin: 50;
  position: relative;
}

.label-styles {
  color: #a9a9a9;
}

.share-delete-box {
  position: relative;
}

.share-delete-box img {
  cursor: pointer;
}

.share-delete-salon-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.flex-tab-navigate {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-inline-end: 100px;
  gap: 24px;
  margin-top: 25px;
  min-width: 234px;
}

.tab-navigate {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.7;
  width: 100%;
  height: 54px;
  background: none;
  border: none;
  cursor: pointer;
  outline: inherit;
  font-family: "RAGSans1.3Light";
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  padding-inline-start: 21px;
}
.tab-naviagte-active {
  color: black;
  background: rgba(235, 235, 235, 0.52);
  opacity: unset;
}
.guests-table {
  font-size: 20px;
  font-family: "RAGSans1.3Regular";
  width: 751px;
}

.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
  flex-direction: column;
  width: 376px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  border-radius: 6px;
  padding: 15px 46px;
  margin-inline-start: -1px;
  margin-bottom: 30px;
  height: 77px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.1s ease-in-out;
}

.switch-field:hover {
  cursor: pointer;
}

.switch-field:first-of-type {
  border-radius: 6px;
}

.switch-field:last-of-type {
  border-radius: 6px;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.checked {
  border: 2px solid black !important;
}

.field-header-switch {
  font-size: 17px;
  font-family: "RAGSans1.3Regular";
  line-height: 25px;
}

.need-witness-inputs input {
  margin: 0 10px;
}

.need-witness-inputs input[type="radio"]:checked {
  accent-color: black !important;
}
.recommended-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: -10px;
  padding-inline-end: 30px;
}
.recommended {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 12px 3px 12px;
  height: 20px;
  background: #e1f1e5;
  border-radius: 9px;
  font-family: "RAG-Sans-1.3";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: #197c17;
}

@media only screen and (max-width: 768px) {
  .switch-field {
    width: 100%;
    margin-inline-start: 0px;
    padding: 12px 22px;
  }

  @media only screen and (max-width: 750px) {
    .switch-field {
      font-size: 12.9px;
    }
  }

  .new-salon-host-header {
    padding: 0;
  }
  .recommended-box {
    width: 85%;
  }
}

@media only screen and (max-width: 750px) {
  .recommended-box {
    width: 89%;
  }
}
