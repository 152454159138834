li a {
  outline: 0;
}

.tablePagination {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  flex: 1 25%;
  font-size: 14px;
  line-height: 21px;
}

.tablePagination li.previous,
.tablePagination li.next {
  width: 81px;
  font-weight: 600;
}

.tablePagination li {
  display: flex;
  justify-content: center;
  margin: 0 0.3rem;
  align-items: center;
  width: 30px;
  height: 30px;
}

.tablePagination li a {
  cursor: pointer;
  font-weight: 600;
}

.PrivateSwitchBase-root-16 {
  padding: 7px !important;
}

.paginationActivePage {
  background: black;
  color: white;
  padding: 3px;
  border-radius: 50%;
}

li.previous,
li.next {
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
}

li.previous.disabled,
li.next.disabled {
  color: gray;
  cursor: auto;
}

.MuiSvgIcon-root {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.MuiFormControlLabel-root {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.MuiTableCell-root {
  font-family: "HeeboRegular", sans-serif !important;
}
/* MuiList-root */
.MuiMenuItem-root	{
  font-size: 16px !important;
}
.MuiListItem-button:hover	{
  text-decoration: none;
  background-color: #db838e !important;
  color: white !important;
}


.MuiTooltip-tooltip {
  max-height: 1000px;
}

.MuiTooltip-popper {
  max-height: 1000px;
  z-index: 101 !important;
}

.ReactModal__Overlay {
  z-index: 100 !important;
}

@media only screen and (max-width: 760px) {
  .ReactModal__Overlay {
    overflow-y: auto;
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

a {
  text-decoration: none;
}

.date-input-container-last-call div.react-datepicker-popper {
  position: fixed !important;
  top: unset !important;
  left: unset !important;
  transform: translate3d(26%, 0px, 8px) !important;
}

.time-input-container-last-call div.react-datepicker-popper {
  position: fixed !important;
  top: unset !important;
  left: unset !important;
  transform: translate3d(26%, 0px, 0px) !important;
}

.date-input-container-availability-assign div.react-datepicker-popper {
  position: fixed !important;
  top: unset !important;
  left: unset !important;
  transform: translate3d(26%, 0px, 8px) !important;
}

.date-input-container-more-info div.react-datepicker-popper {
  position: fixed !important;
  top: unset !important;
  left: unset !important;
  transform: translate3d(26%, 0px, 8px) !important;
}

@media (min-width: 820px) {
  .formContainer.formContainerDate div.react-datepicker-popper {
   position: relative !important;
   transform: unset !important;
   }
}

.react-tel-input {
  direction: ltr !important;
  height: 100%;
}

.react-tel-input .form-control {
  max-width: 19.7rem;
  width: 100% !important;
  max-width: 300px !important;
}

div#mui-component-select-org_type {
  font-size: 1.4rem;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.inputStyle__MenuOption-sc-5o12le-6.lotTjL.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected:hover {
  background-color: #db838e;
  color: white;
}

.MuiInputLabel-root {
  font-family: "RAGSans1.3Light", "HeeboRegular", sans-serif !important;
  font-size: 15px !important;
  color: black !important;
}

/* Targeting react-phone-input-2 input component styles */
/* Mobile */
@media only screen and (max-width: 760px) {
  .phone-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-inline-end: 0;
  }

  .phone-input-container .react-tel-input > input {
    width: 210px;
  }
  .react-tel-input .form-control {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 400px) {
  .phone-input-container .react-tel-input > input {
    width: 180px;
  }
}